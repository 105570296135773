import { API } from "aws-amplify";

export enum ENDPOINTS {
  createProduct = "createProduct",
  getProducts = "getProducts",
  getPresignedS3Url = "getPresignedS3Url",
}

export const generatePresignedUrl = async (fileKey: string) =>
  await API.post(ENDPOINTS.getPresignedS3Url, "/utils/presignedS3Url", {
    body: { fileKey },
  });

export const createProduct = async (values: any, fileKey: string) =>
  await API.post(ENDPOINTS.createProduct, "/products", {
    body: {
      ...values,
      imageKey: fileKey,
    },
  });

export const getProducts = async () =>
  await API.get(ENDPOINTS.getProducts, "/products", {});
