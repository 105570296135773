import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Box, IconButton, Typography, Button } from "@material-ui/core";

import { config } from "../../../config/amplify";
import { CreateGiftDrawer } from "../../gifts/create-drawer";
import { Product } from "../models";
import { ProductImage } from "../product-image";
import { Drawer } from "../../../ui/drawer";

interface Props {
  open: boolean;
  product: Product;
  onClose: (product?: Product) => void;
  delegateGiftCreation?: boolean;
}

export const ProductDetailDrawer = React.memo((props: Props) => {
  const { open, onClose, product, delegateGiftCreation } = props;
  const [giftDrawerOpen, setGiftDrawerOpen] = useState(false);

  const onSelectGiftButtonClick = () => {
    delegateGiftCreation ? onClose(product) : setGiftDrawerOpen(true);
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => onClose()}>
      {product && (
        <>
          <Box p={2}>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={4}>
            <Box
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="220px"
            >
              <ProductImage
                alt="product"
                src={`https://${config.s3.BUCKET}.s3-${config.s3.REGION}.amazonaws.com/${product.imageKey}`}
              />
            </Box>
            <Typography gutterBottom variant="h5">
              <strong>{product.title}</strong>
            </Typography>
            <Typography display="block" variant="body2" gutterBottom>
              {product.description}
            </Typography>
            <Typography>
              <strong>${product.price}</strong>
            </Typography>

            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={onSelectGiftButtonClick}
              >
                <strong>Select this gift</strong>
              </Button>
            </Box>
          </Box>

          <CreateGiftDrawer
            open={giftDrawerOpen}
            onClose={() => setGiftDrawerOpen(false)}
            product={product}
          />
        </>
      )}
    </Drawer>
  );
});
