import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

export const Background = styled(({ url, ...rest }) => <Box {...rest} />)`
  background: url(${({ url }) => url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
