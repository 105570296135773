import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import { AppBar } from "./styled";
import { CreateGiftButton } from "../../features/gifts/create-button";

interface Props {
  onMenuClick: () => void;
}

export const Header = React.memo((props: Props) => {
  const { onMenuClick } = props;

  return (
    <>
      <Box color="white">
        <AppBar position="fixed" color="inherit">
          <Toolbar>
            <Hidden smUp>
              <IconButton
                color="default"
                aria-label="open drawer"
                edge="start"
                onClick={onMenuClick}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Box flexGrow={1}></Box>
            <Hidden xsDown>
              <CreateGiftButton />
            </Hidden>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
});
