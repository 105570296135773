import React, { useState } from "react";
import { useForm } from "react-hook-form";
import RedeemIcon from "@material-ui/icons/Redeem";
import {
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button,
  useTheme,
  fade,
  CardMedia,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";

import { StoreDrawer } from "../../../products/store-drawer";
import { GiftBox } from "./styled";
import { config } from "../../../../config/amplify";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion/styled";
import { CreationStepProps } from "../typings";
import { Values, Selector } from "../../models";
import { Product } from "../../../products/models";

export const Step1 = React.memo((props: CreationStepProps) => {
  const { register, getValues, handleSubmit } = useForm();
  const [whichAccordionOpen, setWhichAccordionOpen] = useState("receiver");
  const [storeDrawerOpen, setStoreDrawerOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const theme = useTheme();
  const { next } = props;

  const onSubmit = async () => {
    const selector = getValues("selector");
    selector === Selector.GIVER
      ? next({
          selector,
          product: selectedProduct,
        })
      : next({
          selector,
          value: parseInt(getValues("value"), 10),
        });
  };

  const handleSelectorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWhichAccordionOpen(e.target.value);
  };

  const handleStoreClose = (product?: Product) => {
    if (product) {
      setSelectedProduct(product);
    }
    setStoreDrawerOpen(false);
  };

  return (
    <>
      <Typography variant="h6" align="center">
        <strong>New gift</strong>
      </Typography>
      <Typography align="center">
        Please select how you want to give a gift
      </Typography>

      <Box mt={3} m={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="selector"
              name="selector"
              defaultValue={Selector.RECEIVER}
              onChange={handleSelectorChange}
            >
              <Accordion
                expanded={whichAccordionOpen === Selector.RECEIVER}
                elevation={0}
                square
              >
                <AccordionSummary>
                  <FormControlLabel
                    inputRef={register()}
                    value={Selector.RECEIVER}
                    control={<Radio color="primary" />}
                    label={
                      <>
                        <Typography color="secondary">
                          <strong>They select</strong>
                        </Typography>
                        <Typography variant="body2">
                          Allow the recipient to freely select from the gift
                          store
                        </Typography>
                      </>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel shrink htmlFor="value-type-select">
                      Set the maximum gift amount
                    </InputLabel>
                    <NativeSelect
                      inputRef={register({
                        required: true,
                      })}
                      inputProps={{
                        name: "value",
                        id: "value-type-select",
                      }}
                    >
                      <option value={Values.XSMALL}>
                        $25 - For the little things...
                      </option>
                      <option value={Values.SMALL}>
                        $50 - Everyday gesture
                      </option>
                      <option value={Values.MEDIUM}>
                        $75 - Did someone get a raise?
                      </option>
                      <option value={Values.LARGE}>
                        $100 - Is someone getting married?
                      </option>
                      <option value={Values.XLARGE}>
                        $200 - Go above and beyond!
                      </option>
                    </NativeSelect>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={whichAccordionOpen === Selector.GIVER}
                elevation={0}
              >
                <AccordionSummary>
                  <FormControlLabel
                    inputRef={register()}
                    value={Selector.GIVER}
                    control={<Radio color="primary" />}
                    label={
                      <>
                        <Typography color="secondary">
                          <strong>You select</strong>
                        </Typography>
                        <Typography variant="body2">
                          You select a specific gift from the gift store
                        </Typography>
                      </>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <GiftBox
                    role="button"
                    onClick={() => setStoreDrawerOpen(true)}
                    height="100px"
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={theme.shape.borderRadius}
                    bgcolor={fade(theme.palette.primary.main, 0.3)}
                  >
                    {!selectedProduct && (
                      <>
                        <RedeemIcon />
                        <Typography variant="caption">+ Select</Typography>
                      </>
                    )}
                    {selectedProduct && (
                      <CardMedia
                        image={`https://${config.s3.BUCKET}.s3-${
                          config.s3.REGION
                        }.amazonaws.com/${selectedProduct!.imageKey}`}
                        style={{ height: 100, width: 100 }}
                      />
                    )}
                  </GiftBox>
                </AccordionDetails>
              </Accordion>
            </RadioGroup>
          </FormControl>
          <Box mt={2}>
            <Grid container spacing={1} wrap="nowrap">
              <Grid item container>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={
                    getValues("selector") === Selector.GIVER && !selectedProduct
                  }
                >
                  <strong>Continue</strong>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
      <StoreDrawer open={storeDrawerOpen} onClose={handleStoreClose} />
    </>
  );
});
