import { Product } from "../../products/models";

export enum DeliveryType {
  LINK = "link",
  DIRECT = "direct",
}
export enum Selector {
  GIVER = "giver",
  RECEIVER = "receiver",
}
export enum Values {
  XSMALL = 25,
  SMALL = 50,
  MEDIUM = 75,
  LARGE = 100,
  XLARGE = 200,
}
export interface Address {
  fullName: string;
  country: string;
  address: string;
  postalCode: number;
  city: string;
}

export interface GiftLinkCreationDTO {
  selector: Selector;
  product?: Product;
  value?: Values;
}

export interface GiftOrderCreationDTO {
  product: Product;
  address: Address;
}
