import React from "react";
import styled from "styled-components";
import MUIAppBar from "@material-ui/core/AppBar";

import { drawerWidth } from "../sidebar/styled";

export const AppBar = styled(({ ...props }) => <MUIAppBar {...props} />)<{
  isAuthenticated: boolean;
}>`
  ${({ theme }) => `
    ${theme.breakpoints.up("sm")} {
      width: calc(100% - ${drawerWidth});
      margin-left: ${drawerWidth};
    }
  `}
`;
