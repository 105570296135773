import React, { FormEvent } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { useAppContext } from "../../../utils/hooks/appContext";
import { GoogleIcon } from "../../../ui/icons/Google";
import { Background } from "../../../ui/background";

const openGoogleLogin = (): Promise<any> => {
  return new Promise((resolve) => {
    const ga = (window as any).gapi.auth2.getAuthInstance();
    ga.signIn().then(
      (googleUser: any) => {
        resolve(googleUser);
      },
      (error: any) => {
        console.log(error);
      }
    );
  });
};

const federatedSignInWithGoogle = async (googleUser: any) => {
  const { id_token, expires_at } = googleUser.getAuthResponse();
  const profile = googleUser.getBasicProfile();
  let user = {
    email: profile.getEmail(),
    name: profile.getName(),
  };

  await Auth.federatedSignIn("google", { token: id_token, expires_at }, user);
};

export const Login = React.memo(() => {
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const onSubmit = async (e: FormEvent) => {
    e.stopPropagation();
    const googleUser = await openGoogleLogin();
    await federatedSignInWithGoogle(googleUser);
    userHasAuthenticated(true);
    history.push("/");
  };

  return (
    <Box height="100vh">
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={12} sm={5} md={4}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            alignContent="center"
            bgcolor="grey"
          >
            <Container maxWidth="sm">
              <Box p={3}>
                <form>
                  <Grid container alignContent="center" justify="center">
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h4" align="center" gutterBottom>
                          <strong>Welcome </strong>
                          <span role="img" aria-label="confetti">
                            🎉
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography align="center" gutterBottom>
                          Log in with your work Google account to start sending
                          meaningful gifts.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          onClick={onSubmit}
                          fullWidth
                          startIcon={<GoogleIcon style={{ fontSize: 32 }} />}
                        >
                          <strong>Log in with Google</strong>
                        </Button>
                      </Grid>
                      <Grid item container justify="center">
                        <Box mt={2} mb={2}>
                          <Typography variant="body2">
                            Not on Phetty?{" "}
                            <a href="https://phetty.com">Get early access</a>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Container>
          </Box>
        </Grid>
        <Grid item sm={7} md={8}>
          <Box position="relative" height="100%">
            <Background
              url={require("./background.png")}
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              zIndex="-1"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});
