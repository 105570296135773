import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";

import { Stepper } from "../../../ui/stepper";
import { Step1 } from "./step-1";
import { Step2 } from "./step-2";
import { Step3 } from "./step-3";
import { CreationStepProps, GiftCreationStepData } from "./typings";
import { Drawer } from "../../../ui/drawer";
import { Box, IconButton } from "@material-ui/core";

const steps = ["Gift", "Delivery", "Share the link / Processing order"];

const getStep = ({
  activeStep,
  creationStepsProps,
}: {
  activeStep: number;
  creationStepsProps: CreationStepProps;
}) => {
  return [
    <Step1 {...creationStepsProps} />,
    <Step2 {...creationStepsProps} />,
    <Step3 {...creationStepsProps} />,
  ][activeStep];
};

interface Props {
  open: boolean;
  onClose: () => void;
  product?: any;
}

export const CreateGiftDrawer = React.memo((props: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [stepData, setStepData] = React.useState<GiftCreationStepData>({});
  const { open, onClose, product } = props;

  useEffect(() => {
    if (product) {
      setActiveStep(1);
    }
    setIsReady(true);
  }, [product]);

  // // debugging
  // useEffect(() => {
  //   console.log({ stepData });
  // }, [stepData]);

  const handleNextStep = (currentStepData: any) => {
    setStepData({
      ...stepData,
      ...currentStepData,
    });

    activeStep === steps.length - 1
      ? complete()
      : setActiveStep(activeStep + 1);
  };

  const complete = () => {};

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      {isReady && (
        <>
          <Box p={1}>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stepper steps={steps} activeStep={activeStep} />

          <Box px={2}>
            {getStep({
              activeStep,
              creationStepsProps: {
                next: handleNextStep,
                previous: () => setActiveStep(activeStep - 1),
                data: {
                  product,
                  ...stepData,
                },
              },
            })}
          </Box>
        </>
      )}
    </Drawer>
  );
});
