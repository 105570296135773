import React from "react";
import { Wrapper } from "./styled";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";

export const Footer = React.memo(() => {
  return (
    <Wrapper>
      <Grid container justify="flex-end">
        <Typography variant="caption">
          With <FavoriteIcon fontSize="inherit" color="error" /> from Phetty.com
          {process.env.REACT_APP_STAGE === "dev" ? (
            <strong> (Beta)</strong>
          ) : (
            ""
          )}
        </Typography>
      </Grid>
    </Wrapper>
  );
});
