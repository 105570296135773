import React from "react";
import { Typography, Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { Store } from "../store";
import { Product } from "../models";
import { Drawer } from "../../../ui/drawer";

interface Props {
  open: boolean;
  onClose: (product?: Product) => void;
}

export const StoreDrawer = React.memo((props: Props) => {
  const { open, onClose } = props;

  return (
    <Drawer anchor="right" open={open} onClose={() => onClose()}>
      <Box p={1}>
        <IconButton aria-label="close" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box p={2}>
        <Box mb={2}>
          <Typography variant="h6">
            <strong>Select a gift</strong>
          </Typography>
          <Typography>
            The selection of all gift of a value up to $25.
          </Typography>
        </Box>
        <Store inDrawer onSelect={(product) => onClose(product)} />
      </Box>
    </Drawer>
  );
});
