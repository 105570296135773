import React from "react";
import { Typography, Grid, TextField, Button, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { Drawer } from "../../../ui/drawer";
import { Product } from "../../products/models";
import { DrawerHeader } from "../../../ui/drawer/header";
import { DrawerContent } from "../../../ui/drawer/content";
import { ProductImage } from "../../products/product-image";
import { config } from "../../../config/amplify";

interface Props {
  open: boolean;
  onClose: () => void;
  product?: Product;
}

export const OrderConfirmationDrawer = React.memo((props: Props) => {
  const { open, onClose, product } = props;
  const { register, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      {product && (
        <>
          <DrawerHeader onCloseClick={onClose}></DrawerHeader>
          <DrawerContent>
            <Typography variant="h6">
              <strong>Confirm</strong>
            </Typography>
            <Typography>
              As soon as you have confirmed the information we will start
              packing.
            </Typography>
            <ProductImage
              alt="product"
              src={`https://${config.s3.BUCKET}.s3-${config.s3.REGION}.amazonaws.com/${product.imageKey}`}
            />

            <form>
              <Typography>
                <strong>Contact</strong>
              </Typography>

              <TextField
                name="email"
                type="email"
                label="Email"
                placeholder="john@doe.com"
                variant="outlined"
                fullWidth
                required
                inputRef={register({
                  required: true,
                })}
                error={!!errors.email}
                helperText={!!errors.email && "Your email is required"}
              />

              <Typography>
                <strong>Address</strong>
              </Typography>

              <Grid container spacing={1} direction="column">
                <Grid item>
                  <TextField
                    name="fullName"
                    type="text"
                    label="Full name"
                    placeholder="John Doe"
                    variant="outlined"
                    fullWidth
                    required
                    inputRef={register({
                      required: true,
                    })}
                    error={!!errors.fullName}
                    helperText={!!errors.fullName && "Your name is required"}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="country"
                    type="text"
                    label="Country"
                    placeholder="Danmark"
                    variant="outlined"
                    fullWidth
                    required
                    inputRef={register({
                      required: true,
                    })}
                    error={!!errors.country}
                    helperText={!!errors.country && "Country is required"}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="address"
                    type="text"
                    label="Address"
                    placeholder="Gadevejen 1"
                    variant="outlined"
                    fullWidth
                    required
                    inputRef={register({
                      required: true,
                    })}
                    error={!!errors.address}
                    helperText={!!errors.address && "Address is required"}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="postalCode"
                    type="number"
                    label="Postal code"
                    placeholder="1200"
                    variant="outlined"
                    fullWidth
                    required
                    inputRef={register({
                      required: true,
                    })}
                    error={!!errors.postalCode}
                    helperText={
                      !!errors.postalCode && "Postal code is required"
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="city"
                    type="text"
                    label="City"
                    placeholder="Copenhagen"
                    variant="outlined"
                    fullWidth
                    required
                    inputRef={register({
                      required: true,
                    })}
                    error={!!errors.city}
                    helperText={!!errors.city && "City is required"}
                  />
                </Grid>
              </Grid>
              <Box mt={1}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  <strong>Confirm</strong>
                </Button>
              </Box>
            </form>
          </DrawerContent>
        </>
      )}
    </Drawer>
  );
});
