import { API } from "aws-amplify";
import { GiftLinkCreationDTO, GiftOrderCreationDTO } from "./models";

export enum ENDPOINTS {
  getGiftByUuid = "getGiftByUuid",
  createGiftLink = "createGiftLink",
}

export const getGiftByLinkUuid = async (uuid: string) =>
  await API.get(ENDPOINTS.getGiftByUuid, `/gifts/link/${uuid}`, {});

export const createGiftLink = async (
  giftLinkCreationDTO: GiftLinkCreationDTO
) =>
  await API.post(ENDPOINTS.createGiftLink, "/gifts/link", {
    body: giftLinkCreationDTO,
  });

export const createGiftOrder = async (
  giftOrderCreationDTO: GiftOrderCreationDTO
) =>
  await API.post(ENDPOINTS.createGiftLink, "/gifts/order", {
    body: giftOrderCreationDTO,
  });
