import React from "react";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid/Grid";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { NativeSelect } from "./styled";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import { StepProps } from "../../../../ui/stepper/typings";

export const Step2 = React.memo((props: StepProps) => {
  const { register, handleSubmit, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { next } = props;

  const onSubmit = () => {
    next({
      limits: getValues(),
    });
  };

  return (
    <>
      <Typography variant="h6">
        <strong>Next, set the limits</strong>
      </Typography>
      <span>
        Create default company-wide gift limits. Later you can tailor limits to
        each team member.
      </span>

      <Box m={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel shrink htmlFor="limit-per-gift-select">
                    Limit per gift
                  </InputLabel>
                  <Box mt={2}>
                    <NativeSelect
                      inputRef={register({
                        required: true,
                      })}
                      inputProps={{
                        name: "limitPerGift",
                        id: "limit-per-gift-select",
                      }}
                    >
                      <option value={25}>$25</option>
                      <option value={50}>$50</option>
                      <option value={100}>$100</option>
                      <option value={200}>$200</option>
                    </NativeSelect>
                  </Box>
                </FormControl>
              </Grid>
              <Grid
                item
                container
                justify="space-between"
                wrap="nowrap"
                spacing={1}
              >
                <Grid item container>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel shrink htmlFor="total-gift-limit-select">
                      Total gift limit
                    </InputLabel>
                    <Box mt={2}>
                      <NativeSelect
                        inputRef={register({
                          required: true,
                        })}
                        inputProps={{
                          name: "totalOccurrenceType",
                          id: "occurrence-type-select",
                        }}
                      >
                        <option value={"daily"}>Daily</option>
                        <option value={"weekly"}>Weekly</option>
                        <option value={"monthly"}>Monthly</option>
                        <option value={"yearly"}>Yearly</option>
                      </NativeSelect>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item container>
                  <FormControl fullWidth variant="outlined">
                    <Box mt={2}>
                      <NativeSelect
                        inputRef={register({
                          required: true,
                        })}
                        inputProps={{
                          name: "totalLimit",
                          id: "total-gift-limit-select",
                        }}
                      >
                        <option value={250}>$250</option>
                        <option value={500}>$500</option>
                        <option value={1000}>$1000</option>
                        <option value={2000}>$2000</option>
                      </NativeSelect>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Button type="submit" endIcon={<ArrowRightAltIcon />}>
            <strong>Save limits</strong>
          </Button>
        </form>
      </Box>
    </>
  );
});
