import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";

import { useAppContext, AppContext } from "../hooks/appContext";

export const PrivateRoute = React.memo(({ children, ...rest }: any) => {
  const { isAuthenticated } = useAppContext();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
});

export const RouteWrapper = React.memo(({ children }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  async function onLoad() {
    try {
      await Auth.currentCredentials();
      userHasAuthenticated(true);
    } catch (e) {}
    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      {!isAuthenticating && children}
    </AppContext.Provider>
  );
});
