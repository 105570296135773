import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";

import { s3Upload, getFileKey } from "./util";
import { generatePresignedUrl, createProduct } from "../api";

export const NewProduct = React.memo(() => {
  const [snackOpen, setSnackOpen] = useState(false);
  const {
    register,
    errors,
    handleSubmit,
    getValues,
    formState,
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const onSubmit = async () => {
    try {
      const { image, ...values } = getValues();
      const file = image[0];
      const fileKey = getFileKey(file);
      const presignedPostData = await generatePresignedUrl(fileKey);
      await s3Upload(file, presignedPostData);
      await createProduct(values, fileKey);
      setSnackOpen(true);
      reset();
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h6">
            <strong>Create a new product</strong>
          </Typography>
          <span>The created product will be added to the product store</span>
          <Box mt={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  required
                  name="title"
                  type="text"
                  label="Product title"
                  placeholder="Apple Airpods"
                  variant="outlined"
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  error={!!errors.title}
                  helperText={!!errors.title && "Product title is required"}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  name="description"
                  type="text"
                  multiline
                  rows={4}
                  label="Product description"
                  placeholder="This product is awesome ..."
                  variant="outlined"
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  error={!!errors.description}
                  helperText={
                    !!errors.description && "Product description is required"
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  name="price"
                  type="number"
                  label="Product price"
                  placeholder="250"
                  variant="outlined"
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">DKK</InputAdornment>
                    ),
                  }}
                  error={!!errors.price}
                  helperText={!!errors.price && "Product price is required"}
                />
              </Grid>
              <Grid item>
                <span>Provide an image of the product</span>
                <Box mt={1}>
                  <input
                    required
                    name="image"
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    ref={register({
                      required: true,
                    })}
                  ></input>
                </Box>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!formState.isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        message="Success, product has been created"
      />
    </>
  );
});
