import React from "react";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import FormHelperText from "@material-ui/core/FormHelperText";

import { StepProps } from "../../../../ui/stepper/typings";

export const Step1 = React.memo((props: StepProps) => {
  const { next } = props;

  const { register, errors, setValue, getValues, handleSubmit } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  React.useEffect(() => {
    const onLoad = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setValue("fullName", user.name);
    };
    onLoad();
  }, [setValue]);

  const onSubmit = () => {
    next({
      details: getValues(),
    });
  };

  return (
    <>
      <Typography variant="h6">
        <strong>First, let’s confirm the details</strong>
      </Typography>
      <span>
        We need this information to tailor the experience to you and your
        company
      </span>

      <Box m={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                name="fullName"
                type="text"
                label="Full name"
                placeholder="John Doe"
                variant="outlined"
                fullWidth
                required
                inputRef={register({
                  required: true,
                })}
                error={!!errors.fullName}
                helperText={!!errors.fullName && "Your name is required"}
              />
            </Grid>
            <Grid item>
              <TextField
                name="companyName"
                type="text"
                label="Company"
                placeholder="Apple"
                variant="outlined"
                fullWidth
                required
                inputRef={register({
                  required: true,
                })}
                error={!!errors.companyName}
                helperText={
                  !!errors.companyName && "Please provide a company name"
                }
              />
            </Grid>
            <Grid item>
              <TextField
                name="website"
                type="text"
                label="Website"
                placeholder="https://apple.com"
                variant="outlined"
                fullWidth
                required
                inputRef={register({
                  required: true,
                })}
                error={!!errors.website}
                helperText={
                  !!errors.website && "Please provide a company website"
                }
              />
            </Grid>
            <Grid item>
              <FormControl
                required
                error={!!errors.termsPrivacy}
                component="fieldset"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="termsPrivacy"
                      color="primary"
                      inputRef={register({
                        required: true,
                      })}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      I agree with the{" "}
                      <a
                        href="https://phetty.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://phetty.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </Typography>
                  }
                />
                <FormHelperText>
                  {!!errors.termsPrivacy && "Please accept before continuing"}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Button type="submit" endIcon={<ArrowRightAltIcon />}>
            <strong>Next</strong>
          </Button>
        </form>
      </Box>
    </>
  );
});
