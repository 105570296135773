import React from "react";
import styled from "styled-components";
import { fade, Box } from "@material-ui/core";

export const GiftBox = styled((props) => <Box {...props} />)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => fade(theme.palette.primary.main, 0.5)};
  }
`;
