import { API } from "aws-amplify";

export enum ENDPOINTS {
  getEmployee = "createProduct",
  postOnboarding = "postOnboarding",
}

export const getEmployee = async (email: string) =>
  await API.get(ENDPOINTS.getEmployee, "/employee", {
    queryStringParameters: {
      email,
    },
  });

export const onboard = async (stepData: any) =>
  await API.post(ENDPOINTS.postOnboarding, "/employee/onboarding", {
    body: stepData,
  });
