import React from "react";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";

import { Wrapper } from "./styled";
import { Stepper } from "../../../ui/stepper";
import { Step1 } from "./step-1";
import { Step2 } from "./step-2";
import { Step3 } from "./step-3";
import { onboard } from "../api";

const steps = [
  "First, let's confirm the details",
  "Next, set the limits",
  "Now, invite your team",
];

const getStep = ({
  activeStep,
  next,
}: {
  activeStep: number;
  next: (currentStepData: any) => void;
}) => {
  return [<Step1 next={next} />, <Step2 next={next} />, <Step3 next={next} />][
    activeStep
  ];
};

export const Onboarding = React.memo(() => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepData, setStepData] = React.useState({});
  const history = useHistory();

  const handleNextStep = (currentStepData: any) => {
    setStepData({
      ...stepData,
      ...currentStepData,
    });

    activeStep === steps.length - 1
      ? complete()
      : setActiveStep(activeStep + 1);
  };

  const complete = async () => {
    try {
      await onboard(stepData);
      history.push("/");
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <Container maxWidth="xs">
      <Wrapper
        container
        alignContent="center"
        justify="center"
        direction="column"
      >
        <Stepper steps={steps} activeStep={activeStep} />

        {getStep({
          activeStep,
          next: handleNextStep,
        })}
      </Wrapper>
    </Container>
  );
});
