const CLIENT_ID =
  "850863239464-0v7s4knhgfrjv3re4ae1p7o94p7c3hfk.apps.googleusercontent.com";

const initGoogleAPI = () => {
  // init the Google SDK client
  const g = (window as any).gapi;
  g.load("auth2", function () {
    g.auth2.init({
      client_id: CLIENT_ID,
      // authorized scopes
      scope: "profile email openid",
    });
  });
};

export const configure = () => {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/platform.js";
  script.async = true;
  script.onload = initGoogleAPI;
  document.body.appendChild(script);
};
