import React, { useEffect, useState } from "react";
import {
  useHistory,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import { Sidebar } from "./ui/sidebar";
import { ToolbarWhitespace } from "./ui/sidebar/styled";
import { AppWrapper, AppContent } from "./App.styled";
import { Header } from "./ui/header";

import { NewProduct } from "./features/products/create";
import { Store } from "./features/products/store";
import { getEmployee } from "./features/authentication/api";
import { CreateGiftButton } from "./features/gifts/create-button";
import { Auth } from "aws-amplify";

export const App = React.memo(() => {
  const [employee, setEmployee] = useState<any>(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    const onLoad = async () => {
      try {
        // temporary: needed for restricting access
        const { email } = await Auth.currentAuthenticatedUser();
        const response = await getEmployee(email);

        if (!response.company) {
          // needs onboarding
          history.push("/onboarding");
          return;
        }

        if (!response.employee) {
          // needs to setup the user within a company
          throw new Error(
            "Second user in a company. Needs to be handled manually for now"
          );
        }

        setEmployee({
          ...response,
          company: {
            ...response.company,
            companyName: response.company.PK.replace("COMPANY#", ""),
          },
        });
      } catch ({ response }) {
        if (response.status === 403) {
          // not invited
          history.push("/coming-soon");
        }
      }
    };
    onLoad();
  }, [history]);

  return (
    <AppWrapper>
      <Header onMenuClick={() => setMobileDrawerOpen(!mobileDrawerOpen)} />
      <Sidebar
        onDrawerClose={() => setMobileDrawerOpen(false)}
        mobileDrawerOpen={mobileDrawerOpen}
      />
      <AppContent>
        <Container maxWidth="lg">
          <ToolbarWhitespace />
          <Switch>
            <Route exact path={`${path}/home`}>
              {employee && (
                <Typography variant="h3">
                  You're home <strong>{employee!.employee.fullName}</strong> at{" "}
                  <strong>{employee!.company.companyName}</strong>
                </Typography>
              )}
            </Route>
            <Route exact path={`${path}/store`}>
              <Store />
            </Route>
            <Route exact path={`${path}/account`}>
              {employee && (
                <Typography variant="h3">
                  You're viewing your account{" "}
                  <strong>{employee!.employee.fullName}</strong> at{" "}
                  <strong>{employee!.company.companyName}</strong>
                </Typography>
              )}
            </Route>
            <Route exact path={`${path}/admin/create-product`}>
              <NewProduct />
            </Route>
            <Redirect exact from={path} to={`${path}/home`} />
          </Switch>
        </Container>
      </AppContent>
      <Hidden smUp>
        <Box position="fixed" bottom={0} width="100%">
          <Paper>
            <Box p={2}>
              <CreateGiftButton fullWidth />
            </Box>
          </Paper>
        </Box>
      </Hidden>
    </AppWrapper>
  );
});

export default App;
