import React from "react";
import Step from "@material-ui/core/Step";
import { StepLabel } from "@material-ui/core";

import { Stepper as StepperStyled } from "./styled";

interface Props {
  activeStep: number;
  steps: string[];
}

export const Stepper = React.memo((props: Props) => {
  const { activeStep, steps } = props;
  return (
    <StepperStyled activeStep={activeStep} alternativeLabel>
      {steps.map((step) => (
        <Step key={step}>
          <StepLabel></StepLabel>
        </Step>
      ))}
    </StepperStyled>
  );
});
