import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Footer } from "./ui/footer";
import { Login } from "./features/authentication/login";
import { Onboarding } from "./features/authentication/onboarding";
import { configure as configureApplication } from "./config";
import { RouteWrapper, PrivateRoute } from "./utils/routes";
import { theme } from "./config/theme";
import { GiftLinkPage } from "./features/gifts/page-gift-link";
import { ComingSoon } from "./features/authentication/coming-soon";

configureApplication();

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouteWrapper>
          <Router>
            <Switch>
              <Route path="/coming-soon">
                <ComingSoon />
              </Route>
              <Route path="/onboarding">
                <Onboarding />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/gift/:uuid">
                <GiftLinkPage />
              </Route>
              <PrivateRoute path="/app">
                <App />
              </PrivateRoute>
              <Redirect exact from="/" to="app" />
            </Switch>
          </Router>
          <Footer />
        </RouteWrapper>
      </ThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
