import React, { ReactNode } from "react";
import { Box, useTheme, Divider, IconButton, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  leftAction?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
  showClose?: boolean;
  onCloseClick?: () => void;
}

export const DrawerHeader = React.memo(
  ({ showClose = true, onCloseClick = () => {}, ...props }: Props) => {
    const { children, leftAction } = props;
    const theme = useTheme();

    return (
      <Box
        border={1}
        borderTop={0}
        borderLeft={0}
        borderRight={0}
        borderColor="grey.300"
        bgcolor={theme.palette.common.white}
      >
        <Grid container alignItems="center">
          <Box>{leftAction}</Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1}>{children}</Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            {showClose && (
              <IconButton aria-label="close" onClick={onCloseClick}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Box>
    );
  }
);
