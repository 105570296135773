import React from "react";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import { StepProps } from "../../../../ui/stepper/typings";

export const Step3 = React.memo((props: StepProps) => {
  const { next } = props;

  const { handleSubmit, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const onSubmit = () => {
    next({
      invites: getValues(),
    });
  };

  return (
    <>
      <Typography variant="h6">
        <strong>Now, invite your team</strong>
      </Typography>
      <span>
        The true value unfolds when Phetty is in the hands of your employees.
      </span>

      <Box m={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  type="text"
                  label="Invite with link"
                  variant="outlined"
                  value="https://app.phetty.com/invite/1234"
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button>COPY</Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="emailOne"
                  type="text"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  name="emailTwo"
                  type="text"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  name="emailThree"
                  type="text"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Button type="submit" endIcon={<ArrowRightAltIcon />}>
            <strong>Finish up</strong>
          </Button>
        </form>
      </Box>

      <span>
        Can be <u>skipped</u> for now
      </span>
    </>
  );
});
