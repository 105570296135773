import React from "react";
import {
  DrawerProps,
  Drawer as MUIDrawer,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useStyles } from "./styled";

export const Drawer = React.memo((props: DrawerProps) => {
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles(matchesDesktop ? "420px" : "100%");
  return (
    <MUIDrawer
      {...props}
      classes={{
        paper: classes.drawerPaper,
      }}
    />
  );
});
