import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Box, Paper, Button } from "@material-ui/core";

import { getGiftByLinkUuid } from "../api";
import { Background } from "../../../ui/background";
import { Avatar } from "./styled";
import { config } from "../../../config/amplify";
import { ProductImage } from "../../products/product-image";
import { Product } from "../../products/models";
import { getCompanyName, Employee } from "../../authentication/models";
import { OrderConfirmationDrawer } from "../order-confirmation-drawer";

export const GiftLinkPage = React.memo(() => {
  const [product, setProduct] = useState<Product | null>(null);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [
    orderConfirmationDrawerOpen,
    setOrderConfirmationDrawerOpen,
  ] = useState(false);
  const { uuid } = useParams();

  useEffect(() => {
    const onLoad = async () => {
      const response: any = await getGiftByLinkUuid(uuid);
      setProduct(response.product);
      setEmployee(response.employee);
      setIsReady(true);
    };
    onLoad();
  }, [uuid]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      <Box height="130px" position="relative" mb={4}>
        <Background
          url="https://source.unsplash.com/1600x900/?company"
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
        />
        <Avatar>{getCompanyName(employee!).substr(0, 2)}</Avatar>
      </Box>
      <Container maxWidth="xs">
        <Typography variant="h5" align="center">
          <strong>{employee!.fullName}</strong>
        </Typography>
        <Typography align="center" color="textSecondary">
          from {getCompanyName(employee!)}
        </Typography>
        <Box my={2} display="flex">
          <Paper elevation={2} style={{ width: "100%" }}>
            <ProductImage
              src={`https://${config.s3.BUCKET}.s3-${
                config.s3.REGION
              }.amazonaws.com/${product!.imageKey}`}
            />
          </Paper>
        </Box>
        <Typography gutterBottom variant="h6">
          <strong>{product!.title}</strong>
        </Typography>
        <Typography display="block" variant="body2" gutterBottom>
          {product!.description}
        </Typography>

        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setOrderConfirmationDrawerOpen(true)}
          >
            <strong>Redeem this gift</strong>
          </Button>
        </Box>
      </Container>

      <OrderConfirmationDrawer
        open={orderConfirmationDrawerOpen}
        onClose={() => setOrderConfirmationDrawerOpen(false)}
        product={product!}
      />
    </>
  );
});
