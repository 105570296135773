import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Grid,
  Button,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Typography,
  Tooltip,
} from "@material-ui/core";

import { createGiftLink, createGiftOrder } from "../../api";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion/styled";
import { CreationStepProps } from "../typings";
import { DeliveryType, Selector, Address } from "../../models";

export const Step2 = React.memo((props: CreationStepProps) => {
  const { register, getValues, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [buttonLabel, setButtonLabel] = useState("Get gift link");
  const [addressFormExpanded, setAddressFormExpanded] = useState(false);
  const { next, data, previous } = props;

  const onSubmit = async () => {
    const {
      deliveryType,
      fullName,
      country,
      address,
      postalCode,
      city,
    } = getValues();

    const shippingAddress: Address = {
      fullName,
      country,
      address,
      postalCode: parseInt(postalCode, 10),
      city,
    };

    if (deliveryType === "link") {
      const response = await createGiftLink({
        product: data.product,
        selector: data.selector! || Selector.GIVER,
        value: data.value,
      });
      next({
        deliveryType,
        giftLink: response,
      });
    } else {
      await createGiftOrder({
        product: data.product!,
        address: shippingAddress,
      });

      next({
        deliveryType,
      });
    }
  };

  const handleDeliveryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setButtonLabel(value === "direct" ? "Send gift" : "Get gift link");
    setAddressFormExpanded(value === "direct");
  };

  return (
    <>
      <Typography variant="h6" align="center">
        <strong>Delivery</strong>
      </Typography>
      <Typography align="center">
        Here you choose the delivery method
      </Typography>

      <Box mt={3} m={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <RadioGroup
              aria-label="delivery method"
              name="deliveryType"
              defaultValue={DeliveryType.LINK}
              onChange={handleDeliveryChange}
            >
              <Box mb={2}>
                <FormControlLabel
                  inputRef={register()}
                  value={DeliveryType.LINK}
                  control={<Radio color="primary" />}
                  style={{ width: "100%" }}
                  label={
                    <>
                      <Typography color="secondary">
                        <strong>Link</strong>
                      </Typography>
                      <Typography variant="body2">
                        Send it to anyone via Email, SMS...
                      </Typography>
                    </>
                  }
                />
              </Box>
              <Accordion expanded={addressFormExpanded} elevation={0}>
                <AccordionSummary>
                  <Tooltip
                    title={
                      data.selector === Selector.RECEIVER
                        ? "Gift selectors will provide their address when they select a gift"
                        : ""
                    }
                    placement="top"
                    arrow
                  >
                    <FormControlLabel
                      inputRef={register()}
                      value={DeliveryType.DIRECT}
                      control={<Radio color="primary" />}
                      disabled={data.selector === Selector.RECEIVER}
                      style={{ width: "100%" }}
                      label={
                        <>
                          <Typography color="secondary">
                            <strong>Direct</strong>
                          </Typography>
                          <Typography variant="body2">
                            Send it directly to an address
                          </Typography>
                        </>
                      }
                    />
                  </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                  {getValues("deliveryType") === DeliveryType.DIRECT && (
                    <Grid container spacing={1} direction="column">
                      <Grid item>
                        <TextField
                          name="fullName"
                          type="text"
                          label="Full name"
                          placeholder="John Doe"
                          variant="outlined"
                          fullWidth
                          required
                          inputRef={register({
                            required: true,
                          })}
                          error={!!errors.fullName}
                          helperText={
                            !!errors.fullName && "Your name is required"
                          }
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="country"
                          type="text"
                          label="Country"
                          placeholder="Danmark"
                          variant="outlined"
                          fullWidth
                          required
                          inputRef={register({
                            required: true,
                          })}
                          error={!!errors.country}
                          helperText={!!errors.country && "Country is required"}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="address"
                          type="text"
                          label="Address"
                          placeholder="Gadevejen 1"
                          variant="outlined"
                          fullWidth
                          required
                          inputRef={register({
                            required: true,
                          })}
                          error={!!errors.address}
                          helperText={!!errors.address && "Address is required"}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="postalCode"
                          type="number"
                          label="Postal code"
                          placeholder="1200"
                          variant="outlined"
                          fullWidth
                          required
                          inputRef={register({
                            required: true,
                          })}
                          error={!!errors.postalCode}
                          helperText={
                            !!errors.postalCode && "Postal code is required"
                          }
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="city"
                          type="text"
                          label="City"
                          placeholder="Copenhagen"
                          variant="outlined"
                          fullWidth
                          required
                          inputRef={register({
                            required: true,
                          })}
                          error={!!errors.city}
                          helperText={!!errors.city && "City is required"}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            </RadioGroup>
          </FormControl>
          <Box mt={2}>
            <Grid container spacing={1} wrap="nowrap">
              <Grid item container>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={previous}
                >
                  <strong>Previous step</strong>
                </Button>
              </Grid>
              <Grid item container>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  <strong>{buttonLabel}</strong>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
});
