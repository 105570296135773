import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

export let theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#106CFF",
    },
    background: {
      default: "#F7F7F9",
    },
  },
});

theme = responsiveFontSizes(theme);
