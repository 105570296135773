import styled from "styled-components";

export const AppWrapper = styled.div`
  display: flex;
`;

export const AppContent = styled.div`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3)}px;
`;
