import React from "react";

import SendIcon from "@material-ui/icons/Send";
import StoreIcon from "@material-ui/icons/Storefront";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import AnalyticsIcon from "@material-ui/icons/InsertChart";
import SettingsIcon from "@material-ui/icons/Settings";

interface SidebarLink {
  link: string;
  name: string;
  icon: React.ReactElement;
}

export const sidebarLinks: SidebarLink[] = [
  {
    link: "home",
    name: "Home",
    icon: <SendIcon />,
  },
  {
    link: "store",
    name: "Store",
    icon: <StoreIcon />,
  },
  {
    link: "account",
    name: "Account",
    icon: <PersonIcon />,
  },
];

export const adminLinks: SidebarLink[] = [
  {
    link: "manage/users",
    name: "Users",
    icon: <PeopleIcon />,
  },
  {
    link: "manage/analytics",
    name: "Analytics",
    icon: <AnalyticsIcon />,
  },
  {
    link: "manage/settings",
    name: "Settings",
    icon: <SettingsIcon />,
  },
];
