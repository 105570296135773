import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import MUIListItemIcon from "@material-ui/core/ListItemIcon";
import MUIListItem from "@material-ui/core/ListItem";
import { NavLink as RouterNavLink } from "react-router-dom";

export const drawerWidth = "240px";

export const useStyles = makeStyles({
  drawerPaper: {
    width: drawerWidth,
  },
});

export const ToolbarWhitespace = styled.div`
  ${({ theme }) => theme.mixins.toolbar};
  height: 1px; // hack to fix Safari
`;

export const Nav = styled.nav`
  ${({ theme }) => `
    ${theme.breakpoints.up("sm")} {
      width: ${drawerWidth};
      flex-shrink: 0;
    }
  `}
`;

export const ListItemIcon = styled(MUIListItemIcon)``;
export const ListItem = styled(MUIListItem)``;

export const NavLink = styled(RouterNavLink)`
  & {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &:visited {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &.sidebar-link--active {
    ${ListItem} {
      background: ${({ theme }) => theme.palette.action.selected};
    }
    ${ListItemIcon} {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
