import React from "react";
import { Box, Container } from "@material-ui/core";

export const ComingSoon = React.memo(() => {
  return (
    <Container maxWidth="lg">
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <strong>Coming soon!</strong>
      </Box>
    </Container>
  );
});
