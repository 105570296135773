import Amplify from "aws-amplify";
import { ENDPOINTS as PRODUCT_ENDPOINTS } from "../../features/products/api";
import { ENDPOINTS as AUTHENTICATION_ENDPOINTS } from "../../features/authentication/api";
import { ENDPOINTS as GIFT_ENDPOINTS } from "../../features/gifts/api";

const development = {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "phetty-api-dev-attachmentsbucket-1t9pd2enl535",
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://enay02z41l.execute-api.eu-west-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_fKcquwrYF",
    APP_CLIENT_ID: "3huqi034dceql9thsppdqftp9b",
    IDENTITY_POOL_ID: "eu-west-1:76666566-9ee7-43ac-8736-b5e048787165",
  },
  oauth: {
    domain: "phetty.auth.eu-west-1.amazoncognito.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://app.phetty.com/",
    redirectSignOut: "https://app.phetty.com/logout",
    responseType: "token",
  },
};

const production = {
  s3: {
    REGION: "",
    BUCKET: "",
  },
  apiGateway: {
    REGION: "",
    URL: "",
  },
  cognito: {
    REGION: "",
    USER_POOL_ID: "",
    APP_CLIENT_ID: "",
    IDENTITY_POOL_ID: "",
  },
  oauth: {},
};

export const config =
  process.env.REACT_APP_STAGE === "prod" ? production : development;

export const configure = () => {
  Amplify.configure({
    oauth: config.oauth,
    Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
    },
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    API: {
      endpoints: [
        ...Object.keys(AUTHENTICATION_ENDPOINTS).map((endpoint) => ({
          name: endpoint,
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        })),
        ...Object.keys(PRODUCT_ENDPOINTS).map((endpoint) => ({
          name: endpoint,
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        })),
        ...Object.keys(GIFT_ENDPOINTS).map((endpoint) => ({
          name: endpoint,
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        })),
      ],
    },
  });
};
