import { Avatar as MUIAvatar } from "@material-ui/core";
import styled from "styled-components";

export const Avatar = styled(MUIAvatar)`
  height: 56px;
  width: 56px;
  position: absolute;
  bottom: -28px;
  left: 0;
  right: 0;
  margin: auto;
`;

export const Image = styled.img``;
