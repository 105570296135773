import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { CreationStepProps } from "../typings";

export const Step3 = React.memo((props: CreationStepProps) => {
  const { data } = props;
  return (
    <>
      {data.deliveryType === "link" && (
        <>
          <Typography variant="h6" align="center" gutterBottom>
            <strong>Now send it!</strong>
          </Typography>
          <Typography align="center">
            Remember that the message you send with the gift makes a huge
            difference. So make it count
          </Typography>

          <Box mt={2} mb={2}>
            <TextField
              type="text"
              variant="outlined"
              value={`https://app.phetty.com/gift/${data.giftLink.PK.replace(
                "LINK#",
                ""
              )}`}
              fullWidth
              inputProps={{
                readOnly: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button>COPY</Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Typography variant="caption" align="center" display="block">
            One link per recipient - will expire in 30 days{" "}
          </Typography>
        </>
      )}
      {data.deliveryType === "direct" && (
        <>
          <Typography variant="h6" align="center" gutterBottom>
            <strong>On its way!</strong>
          </Typography>
          <Typography align="center">
            We will now send you gift to the address that you have specified.
          </Typography>
        </>
      )}
    </>
  );
});
