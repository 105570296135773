import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

import { TypographyMaxHeight } from "./styled";
import { config } from "../../../config/amplify";
import { getProducts } from "../api";
import { ProductDetailDrawer } from "../detail-drawer";
import { Product } from "../models";

interface Props {
  inDrawer?: boolean;
  onSelect?: (product: Product) => void;
}

export const Store = React.memo((props: Props) => {
  const { inDrawer, onSelect } = props;
  const [products, setProducts] = useState<[] | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productDrawerOpen, setProductDrawerOpen] = useState(false);

  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);
    setProductDrawerOpen(true);
  };

  useEffect(() => {
    const onLoad = async () => {
      const response = await getProducts();
      setProducts(response);
    };

    onLoad();
  }, []);

  if (!products) {
    return null;
  }

  const handleProductDetailDrawerClose = (product?: any) => {
    if (inDrawer && onSelect && product) {
      onSelect(product);
    }
    setProductDrawerOpen(false);
  };

  const GridItem = ({ children }: { children: any }) =>
    inDrawer ? (
      <Grid item container xs={6}>
        {children}
      </Grid>
    ) : (
      <Grid item container xs={12} sm={6} md={4}>
        {children}
      </Grid>
    );

  return (
    <>
      <Grid container spacing={1}>
        {products!.map((product: Product) => (
          <GridItem key={product.SK}>
            <Box width="100%">
              <Box mb={1}>
                <Card>
                  <CardActionArea onClick={() => handleProductClick(product)}>
                    <Box p={1}>
                      <Grid container justify="flex-end">
                        <Grid item>
                          <Typography variant="body2">
                            <strong>${product.price}</strong>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <CardMedia
                      image={`https://${config.s3.BUCKET}.s3-${config.s3.REGION}.amazonaws.com/${product.imageKey}`}
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography>
                        <strong>{product.title}</strong>
                      </Typography>
                      <TypographyMaxHeight
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        {product.description}
                      </TypographyMaxHeight>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            </Box>
          </GridItem>
        ))}
      </Grid>
      <ProductDetailDrawer
        open={productDrawerOpen}
        product={selectedProduct!}
        onClose={handleProductDetailDrawerClose}
        {...(inDrawer ? { delegateGiftCreation: true } : {})}
      />
    </>
  );
});
