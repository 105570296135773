import React, { useState } from "react";
import Button from "@material-ui/core/Button";

import { CreateGiftDrawer } from "../create-drawer";

interface Props {
  fullWidth?: boolean;
}

export const CreateGiftButton = React.memo((props: Props) => {
  const { fullWidth } = props;

  const [giftDrawerOpen, setGiftDrawerOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setGiftDrawerOpen(true)}
        fullWidth={fullWidth}
      >
        <strong>Send a gift</strong>
      </Button>
      <CreateGiftDrawer
        open={giftDrawerOpen}
        onClose={() => setGiftDrawerOpen(false)}
      />
    </>
  );
});
