import styled from "styled-components";
import {
  Accordion as MUIAccordion,
  AccordionSummary as MUIAccordionSummary,
  AccordionDetails as MUIAccordionDetails,
} from "@material-ui/core";

export const Accordion = styled(MUIAccordion)`
  &.MuiAccordion-root {
    margin: 0;
    &:before {
      height: 0px;
    }
  }
`;

export const AccordionSummary = styled(MUIAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0px;
    padding-bottom: ${({ theme }) => theme.spacing(3)}px;

    & .MuiAccordionSummary-content {
      margin: 0px;
    }
  }
`;

export const AccordionDetails = styled(MUIAccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0px;
  }
`;
