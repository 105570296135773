import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import { Auth } from "aws-amplify";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  useStyles,
  ToolbarWhitespace,
  Nav,
  ListItem,
  ListItemIcon,
  NavLink,
} from "./styled";
import { BrandLogo } from "../icons/Brand";
import { sidebarLinks, adminLinks } from "./constants";

interface Props {
  onDrawerClose: () => void;
  mobileDrawerOpen: boolean;
}

export const Sidebar = React.memo((props: Props) => {
  const { mobileDrawerOpen, onDrawerClose } = props;
  const [logOutDialogOpen, setLogOutDialogOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleLogOut = () => {
    setLogOutDialogOpen(true);
  };

  const handleLogOutDialogClose = async (signOut: boolean) => {
    setLogOutDialogOpen(false);

    if (signOut) {
      try {
        await Auth.signOut();
        history.push("/login");
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const drawerContent = (onLinkClicks: () => void) => (
    <Box display="flex" height="100%" flexDirection="column">
      <ToolbarWhitespace>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <BrandLogo />
        </Box>
      </ToolbarWhitespace>
      <Divider />
      <Box flex={1}>
        <List>
          {sidebarLinks.map((sidebarLink) => (
            <NavLink
              onClick={onLinkClicks}
              exact
              key={sidebarLink.link}
              to={`${url}/${sidebarLink.link}`}
              className="sidebar-link"
              activeClassName="sidebar-link--active"
            >
              <ListItem button>
                <ListItemIcon>{sidebarLink.icon}</ListItemIcon>
                <ListItemText primary={<strong>{sidebarLink.name}</strong>} />
              </ListItem>
            </NavLink>
          ))}
        </List>
        <Divider />
        <List>
          {adminLinks.map((adminLink) => (
            <NavLink
              exact
              key={adminLink.link}
              to={`${url}/${adminLink.link}`}
              className="sidebar-link"
              activeClassName="sidebar-link--active"
            >
              <ListItem button>
                <ListItemIcon>{adminLink.icon}</ListItemIcon>
                <ListItemText primary={<strong>{adminLink.name}</strong>} />
              </ListItem>
            </NavLink>
          ))}
        </List>
        <Divider />
        <List>
          <NavLink
            exact
            to={`${url}/admin/create-product`}
            className="sidebar-link"
            activeClassName="sidebar-link--active"
          >
            <ListItem button>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={<strong>Create a product</strong>} />
            </ListItem>
          </NavLink>
        </List>
      </Box>
      <List>
        <ListItem button onClick={handleLogOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText
            primary={<strong>Log out</strong>}
            secondary={"Bye bye"}
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Nav>
      <Hidden smUp>
        <Drawer
          variant="temporary"
          open={mobileDrawerOpen}
          onClose={onDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{ keepMounted: true }}
        >
          {drawerContent(onDrawerClose)}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          open
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawerContent(() => {})}
        </Drawer>
      </Hidden>

      <Dialog
        open={logOutDialogOpen}
        onClose={handleLogOutDialogClose}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">
          {"Signing out from Phetty"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to sign out ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleLogOutDialogClose(false)}
            color="primary"
          >
            No, stay
          </Button>
          <Button
            onClick={() => handleLogOutDialogClose(true)}
            color="primary"
            autoFocus
          >
            Yes, log out
          </Button>
        </DialogActions>
      </Dialog>
    </Nav>
  );
});
